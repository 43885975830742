import {
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  IconButton,
  Flex,
  Icon,
} from '@chakra-ui/react';
import {
  FaEthereum,
  FaYinYang,
  FaStore,
  FaUser,
  FaQuestionCircle,
  FaFileContract,
  FaBookOpen,
  FaShieldAlt,
} from 'react-icons/fa';
import { GiTwirlyFlower } from "react-icons/gi";
import {
  BrowserRouter as Router,
  NavLink as RouterLink, // <-- import the NavLink component
} from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FcAbout } from 'react-icons/fc';
import { MdGamepad } from 'react-icons/md';
import {
  RiHomeHeartLine,
  RiHomeHeartFill,
  RiMoonClearFill,
} from 'react-icons/ri';
import { VscJson } from 'react-icons/vsc';

function NavMenu({ isGameRoute }) {
  const _style = isGameRoute
    ? {}
    : {
        position: 'absolute',
        top: '0%',
        left: '0%',
      };
  const RenderMenuItem = ({ _icon, _to, _title }) => {
    return (
      <MenuItem>
        <Link
          as={RouterLink}
          to={_to}
          p={2}
          _activeLink={{ fontWeight: 'bold' }}
        >
          <Flex
            justifyContent="flex-start"
            ml={4}
            fontSize="1.25rem"
            alignItems="center"
          >
            <Icon as={_icon} mr={2} />
            {_title}
          </Flex>
        </Link>
      </MenuItem>
    );
  };
  return (
    <div style={_style}>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon w={7} h={7} />}
          variant="outline"
        ></MenuButton>
        <MenuList>
          <RenderMenuItem _icon={RiHomeHeartLine} _to="/" _title="Home" />
          <RenderMenuItem _icon={FaBookOpen} _to="/about" _title="About" />
          <RenderMenuItem
            _icon={FaEthereum}
            _to="/blockchaingames"
            _title="Cozy Blockchain Games"
          />
          <RenderMenuItem
            _icon={MdGamepad}
            _to="/digitalgames"
            _title="Cozy Digital Games"
          />
          <RenderMenuItem
            _icon={FaYinYang}
            _to="/allcozygames"
            _title="All Cozy Games"
          />
          <RenderMenuItem
            _icon={GiTwirlyFlower}
            _to="/magicflowers"
            _title="Magic Flowers"
          />
          <RenderMenuItem
            _icon={RiMoonClearFill}
            _to="/nftbazaar"
            _title="Serene NFT Bazaar"
          />

          <RenderMenuItem _icon={FaUser} _to="/user" _title="User" />
     
          <RenderMenuItem _icon={FaQuestionCircle} _to="/faq" _title="FAQ" />
          <RenderMenuItem
            _icon={FaFileContract}
            _to="/termsandconditions"
            _title="Terms and Conditions"
          />

          <RenderMenuItem
            _icon={VscJson}
            _to="/jsoncreator"
            _title="Json Creator"
          />
        </MenuList>
      </Menu>
    </div>
  );
}

export default NavMenu;
